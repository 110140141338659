import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

//import fakeAuth from "./fakeAuth.js";
import Login from "./components/Login.js";
import Home from "./components/Home.js";
import Users from "./components/Users.js";
import User from "./components/User.js";
import About from "./components/About.js";
import Logout from "./components/Logout.js";
import './App.css';

class App extends Component {
  constructor(props)
  {
    super(props);
    this.state = {
      isLoaded:false,
      isLogged:false,
      email: '',
      password: '',
      token:'',
      alert:''
    };
  }
  
  render() {
    return (
      <Router>
      <div id="main" className="align-items-center">
        <Route path="/" exact component={Login}  />
        <Route path="/dashboard/" render={props => <Home token={this.state.token} {...props} />} />
        <Route path="/users/" render={props => <Users token={this.state.token} {...props} />} />
        <Route path="/user/:id" render={props => <User token={this.state.token} u_id={this.user_id} {...props} />} />
        <Route path="/about/" render={props => <About token={this.state.token} {...props} />} />
        <Route path="/logout/" component={Logout} />
      </div>
    </Router>
    );
  }
}

export default App;