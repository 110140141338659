import React, { Component } from 'react';
//import { withRouter,BrowserRouter as Router, Route, Link } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";

class Header extends Component {
  render() {
    let token = (this.props.token&&this.props.token!=='undefined')?this.props.token:null;
    if(localStorage.getItem('token'))token=localStorage.getItem('token');
    return (
      <div id="header">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to={{ 
          pathname: '/dashboard/', 
          state: { token: token }, 
          token: token
        }}>My App</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarMain">
          <ul className="navbar-nav mr-auto">
          <li className={ ((this.props.location && this.props.location.pathname==='/dashboard/') ? 'nav-item active' : 'nav-item') }>
              <Link className="nav-link" to={{
                pathname: '/dashboard/',
                state: { token: token },
                token: token
              }}>Home</Link>
            </li>
            <li className={ ((this.props.location && this.props.location.pathname==='/users/') ? 'nav-item active' : 'nav-item') }>
            <Link className="nav-link" to={{
                pathname: '/users/', 
                state: { token: token } 
              }}>Users</Link>
            </li>
            <li className={ ((this.props.location && this.props.location.pathname==='/about/') ? 'nav-item active text-right' : 'nav-item text-right') }>
            <Link className="nav-link" to={{
                pathname: '/about/', 
                state: { token: token } 
              }}>About</Link>
            </li>
            <li className="nav-item text-right">
              <Link className="nav-link" to="/logout">Logout</Link>
            </li>
          </ul>
        </div></div>
      </nav></div>
    );
  }
}

export default withRouter(Header);