import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoaded:false,
        isLogged:false,
        email: '',
        password: '',
        token:'',
        alert:''
      };
  }

  updateEmail(value) {
    this.setState({
      email: value,
    });
  }
  updatePassword(value) {
    this.setState({
      password: value,
    });
  }

  handleError(){
    this.props.history.push("/");

    this.setState({
      isLoaded: true,
      isLogged: false,
      alert:'Invalid credentials!',
      token:''
    });
  }

  submit() {
    /**
    *
    email: eve.holt@reqres.in
    password: cityslicka
    *
    **/
    if(this.state.email==='' || this.state.password==='')this.handleError();
    else{
      fetch("https://reqres.in/api/login", 
      {
        method: 'POST',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: 'email='+this.state.email+'&password='+this.state.password
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(result&&result.token)
            {
              this.setState({
                isLoaded: true,
                isLogged: true,
                alert:'',
                token:result.token
              });
              localStorage.setItem('token', result.token);
              this.props.history.push({pathname:"/dashboard/",token:result.token,state: { isLogged:true,token:result.token }});
            }
            else this.handleError();
          },
          (error) => {
            this.handleError();
          }
        )
    }
  }

  render() {
    let token = (this.props.location.state&&this.props.location.token!=='undefined')?this.props.location.state.token:null;
    if(localStorage.getItem('token'))token=localStorage.getItem('token');
    if(token){
      return <Redirect to='/dashboard' />
    }
    else {
      return (
          <div className="form-signin">
            <header className="app-header text-center mb-3">
              <h1 className="mb-3">Login</h1><h2 className="my-3">{ (this.state.alert)?this.state.alert:'' }</h2>
            </header>
              <form action="#" method="post" onSubmit={(e) => {e.preventDefault();this.submit()}}>
              <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" placeholder="Enter email" required onChange={(event) => {this.updateEmail(event.target.value)}} value={this.state.email} />
              </div>
              <div className="form-group mb-3">
                <label>Password</label>
                <input type="password" className="form-control" placeholder="Password" required onChange={(event) => {this.updatePassword(event.target.value)}} value={this.state.password} />
              </div>
              <div className="clearfix mb-3"></div>
              <button type="submit" onClick={() => {this.submit()}} className="btn btn-primary mt-3 btn-block">Submit</button>
              </form>
          </div>
      );
    }
  }
}

export default Login;