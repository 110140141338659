import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from "./includes/Header.js";
import UserForm from "./includes/UserForm.js";

class User extends Component {
  constructor(props) {
    super(props);
    let get_user=(localStorage.getItem('user_id'))?localStorage.getItem('user_id'):this.props.location.u_id;
    if(!get_user){
      localStorage.setItem("user_id",this.props.location.u_id);
      get_user=this.props.location.u_id
    }
    
    this.state={
      user_id:get_user,
      userAccount:{},
      isLoaded: false,
      alert:null,
      showForm:false
    };
    this.componentDidMount.bind(this);
  }
  handleError(){
    this.setState({
      userAccount:{},
      isLoaded: true,
      alert:'No data found!'
    });
    return false;
  }
  fetchUserData(){
    let {get_user}=this.state.user_id;
    console.log(this.props.location.u_id);
    fetch("https://reqres.in/api/users/"+get_user,
      {
        method: 'GET',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(result&&result.data)
            {
              this.setState({
                userAccount: result.data,
                isLoaded: true,
                alert:''
              });
              return result.data;
            }
            else {
              this.handleError();
            }
          },
          (error) => {
            this.handleError();
          }
        )
        
    return false;
  }
  enableForm(){
    this.setState(state => ({ showForm: !state.showForm }));
  }  
  componentDidMount() {
    console.log(this.props.location.u_id);
    this.fetchUserData();
  }
  render() {
    let token = (this.props.location.state&&this.props.location.token!=='undefined')?this.props.location.state.token:null;
    if(localStorage.getItem('token'))token=localStorage.getItem('token');
    if(!token)
    {
      return (
      <div className="container align-items-center my-3"><div className="row">
          <h1 className="text-center">Not allowed</h1>
      </div></div>
      )
    }
    else
    {
        if(!this.state.isLoaded){
          return  <div className="container align-items-center mt-3 text-center"><div className="row mt-3"><div className="col-12 mt-3"><img src="https://media2.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="Loading"></img></div></div></div>
        }
        else{
          let userData=(this.state.userAccount)?this.state.userAccount:'';
          let avatar=userData.avatar;
          return (<div><Header render={props => <Header token={token} {...props} />} />
          <UserForm showForm={this.state.showForm} userData={userData}></UserForm>
          <div className="container align-items-center mt-3">
              <div className="row mt-3">
                  <div className="col-6 mt-3">
                      <h1>{userData.first_name} {userData.last_name}</h1>
                  </div>

                  <div className="col-6 text-right mt-3"><Link className="btn btn-primary" to={{
                  pathname: '/users/',
                  state: { token: token },
                  token: token
                }}>Back</Link></div>
              </div>

                <div className="row mt-3">
                  <div className="col-2">
                      <img src={avatar} alt="{userData.first_name} {userData.last_name}" />
                  </div>
                  <div className="col-6">
                      <div className="text-left">
                        <h5 className="card-title">{userData.email}</h5>
                        <p className="card-text">{userData.first_name} {userData.last_name}</p>
                      </div>
                  </div>
                  <div className="col-4">
                      <button className="btn" onClick={this.enableForm.bind(this)}>Edit</button>
                  </div>
                </div>
          </div>
        </div>)
        }
    }
    
  }
}

export default User;