import React, { Component } from 'react';
import Header from "./includes/Header.js";

class About extends Component {
  constructor(props){
    super(props);
    this.state={
      transition:'fadeIn'
    };
  }
  componentDidMount(){
    this.setState({transition:'fadeIn'})
  }
  componentWillUnmount(){
    this.setState({transition:'fadeOut'})
  }
  render() {
    let token = (this.props.location.state&&this.props.location.token!=='undefined')?this.props.location.state.token:null;
    if(localStorage.getItem('token'))token=localStorage.getItem('token');

    if(token){
      return (
        <div className={this.state.transition}>
          <Header/>
          <div className="container mt-3"><div className="row mt-3">
              <div className="col-12"><h1 className="mt-3">About us</h1><p>Isto é do melhor!</p><p>Uma web rich application feita em ReactJS com React Router.</p></div>
          </div></div>
        </div>
      );
    }
    else{
      return (
      <div className="container align-items-center my-3"><div className="row mt-3">
          <div className="col"><h1 className="text-center">Not allowed</h1></div>
      </div></div>
      )
    }
  }
}

export default About;