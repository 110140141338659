import React, { Component } from 'react';
import Header from "./includes/Header.js";

class Home extends Component {
  render() {
    let token = (this.props.location.state&&this.props.location.token!=='undefined')?this.props.location.state.token:null;
    if(localStorage.getItem('token'))token=localStorage.getItem('token');
    let location=this.props.location.pathname;
    
    if(!token)
    {
      return (
      <div className="container align-items-center my-3"><div className="row mt-3">
         <div className="col"><h1 className="text-center">Not allowed</h1></div>
      </div></div>
      )
    }
    else
    {
        return (<div><Header render={props => <Header location={location} token={token} {...props} />} />
        <div className="container align-items-center mt-3"><div className="row mt-3">
          <div className="col-12"><h1 className="mt-3 mb-3">Hello User!</h1></div>
            
          <div className="col-12"><h2>Your token:</h2><h2>{token}</h2></div>
          
        </div></div>

        <div className="container align-items-center mt-3"><div className="row mt-3">
          <div className="col-12">Future widgets go here</div>
          
        </div></div>
      </div>)
    }
    
  }
}

export default Home;