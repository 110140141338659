import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Header from "./includes/Header.js";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state={
      userList:[],
      total:0,
      isLoaded: false,
      alert:null
    };
  }
  handleError(){
    this.setState({
      userList:[],
      total:0,
      isLoaded: true,
      alert:'No users found!'
    });
    return false;
  }
  fetchUsers(page_nr=2){
    fetch("https://reqres.in/api/users?page="+page_nr,
      {
        method: 'GET',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(result&&result.total)
            {
              this.setState({
                userList: result.data,
                isLoaded: true,
                total:result.total
              });
              return result.data;
            }
            else {
              this.handleError();
            }
          },
          (error) => {
            this.handleError();
          }
        )
        
    return false;
  }
  componentDidMount() {
    this.fetchUsers()
  }
  render() {
    let token = (this.props.location.state&&this.props.location.token!=='undefined')?this.props.location.state.token:null;
    if(localStorage.getItem('token'))token=localStorage.getItem('token');
    if(!token)
    {
      return (
      <div className="container align-items-center my-3"><div className="row">
         <div className="col"><h1 className="text-center">Not allowed</h1></div>
      </div></div>
      )
    }
    else
    {
      if(!this.state.isLoaded){
        return  <div className="container align-items-center mt-3 text-center"><div className="row mt-3"><div className="col-12 mt-3"><img src="https://media2.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="Loading"></img></div></div></div>
      }
      else{
        return (<div><Header render={props => <Header token={token} {...props} />} />
        <div className="container align-items-center mt-3">
            <div className="row mt-3">
                <div className="col-12 mt-3">
                    <h1>Users</h1> <h2 className="my-3">{ (this.state.alert&&this.state.alert!=null)?this.state.alert:'' }</h2>
                    </div>
                </div>

              <div className="row mt-3">
              {this.state.userList.map((value, index) => {
                    let user_id=value.id,
                    avatar=value.avatar,
                    pStyle = {
                        backgroundImage: "url(" + avatar + ")",
                    };
                    return <div className="col-3 mb-3" key={index}><div className="card">
                        <div className="card-avatar" style={pStyle}></div>
                        <div className="card-body text-center">
                        <h5 className="card-title">{value.email}</h5>
                        <p className="card-text">{value.first_name} {value.last_name}</p>
                        <Link className="btn btn-primary" to={{
                    pathname: '/user/'+user_id,
                    state: { token: token },
                    u_id:user_id,
                    token: token
                  }}>View</Link>
                    </div></div></div>
              })}
                </div>
            
          
        </div>
      </div>)
      }
    }
    
  }
}

export default Users;