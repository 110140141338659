import React, { Component } from 'react';

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded:false,
      isLogged:false,
      email: '',
      password: '',
      token:'',
      alert:'Session logout ok'
    };
    localStorage.clear();
    this.props.location.state=null;
    this.props.history.push("/");
  }

  render() {
    return (
      <div id="main" className="align-items-center">
        <div className="container">
          <div className="row">
          <header className="app-header">
            <div className="col-12"><h1>Hello world { this.state.alert }</h1></div>
          </header>
          </div>
       

        <div className="row">
          
            <div className="form-group col-6">
              <label>Email</label>
              <input type="email" className="form-control" placeholder="Enter email" onChange={(event) => {this.updateEmail(event.target.value)}} value={this.state.email} />
            </div>
            <div className="form-group col-6">
              <label>Password</label>
              <input type="password" className="form-control" placeholder="Password" onChange={(event) => {this.updatePassword(event.target.value)}} value={this.state.password} />
            </div>
            <div className="clearfix"></div>
            <div className="col-12"><button type="submit" onClick={() => {this.submit()}} className="btn btn-primary">Submit</button></div>
          
        </div></div>
      </div>
    );
  }
}

export default Logout;