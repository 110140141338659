import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";
class UserForm extends Component {
  constructor(props) {
      super(props);
      //user_id:this.props.location.u_id,
      this.state={
        user_first_name:this.props.userData.first_name,
        user_last_name:this.props.userData.last_name,
        user_job:this.props.userData.job
      };
  }
  updateFirstName(value) {
    this.props.userData.first_name=value;
    this.setState({
      user_first_name: value,
    });
  }
  updateLastName(value) {
    this.setState({
      user_last_name: value,
    });
  }
  updateJob(value) {
    this.setState({
      user_job:value,
    });
  }
  editForm(user_id){
    user_id=2;
    //user_id= this.props.userData.id;
    var fName=this.state.user_first_name;
    var lName=this.state.user_last_name;
    var job=this.state.user_job;
    var name=fName+' '+lName;
    fetch("https://reqres.in/api/users/"+user_id,
      {
        method: 'PUT',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: 'name='+name+'&job='+job
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(result)
            {
              console.log(result)
              return result;
            }
            else {
              //this.handleError();
            }
          },
          (error) => {
            //this.handleError();
          }
        )
        return ;
  }
  render() {
      if(this.props.showForm){
      return (<div className="container align-items-center mt-3">
      <form className="fadeIn">
        <legend>{this.props.userData.email}</legend>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">First Name</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" value={this.props.userData.first_name} onChange={(event) => {this.updateFirstName(event.target.value)}} placeholder="First Name" />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Last Name</label>
          <div className="col-sm-10">
            <input type="text" className="form-control" onChange={(event) => {this.updateLastName(event.target.value)}} placeholder="Last Name" />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Job</label>
          <div className="col-sm-10">
          <input type="text" className="form-control" onChange={(event) => {this.updateJob(event.target.value)}} placeholder="Job" />
          </div>
        </div>
        <div className="form-group row">
        <div className="col-sm-10"><button className="btn btn-primary" onClick={this.editForm.bind(this)}>Save</button></div>
        </div>
      </form>
    </div>)
    }
    else return null;
  }
}
export default UserForm;